//
// _blog-job.scss
//

/*********************************/
/*         Blog & News           */
/*===============================*/
@each $name,
$value in $theme-colors {
    .blog {
        &.blog-#{$name} {
            .tag {
                background: $value !important;
            }
            .title,
            .readmore {
                &:hover {
                    color: $value !important;
                }
            }
            .link {
                color: $value !important;
            }
        }
    }
}

.blog {
    transition: all 0.3s ease;
    .content {
        h4 {
            line-height: 1.2;
        }
        .title {
            transition: all 0.5s ease;
        }
        .post-meta {
            .like, .comments, .readmore {
                font-size: 15px;
                transition: all 0.5s ease;
            }
            .like {
                &:hover {
                    color: $danger !important;
                }
            }
            .comments {
                &:hover {
                    color: $success !important;
                }
            }
        }
    }
    .author,
    .teacher,
    .preview,
    .course-fee {
        position: absolute;
        z-index: 1;
        opacity: 0;
        transition: all 0.5s ease;
    }
    .author {
        top: 5%;
        left: 5%;
    }
    .author,
    .teacher {
        .user,
        .date,
        .profession {
            color: $white;
        }
    }
    .teacher {
        bottom: 6%;
        left: 5%;
    }
    .course-fee {
        bottom: -5%;
        right: 5%;
        width: 50px;
        height: 50px;
        .fee {
            line-height: 50px;
        }
    }
    &.blog-detail {
        &:hover {
            transform: translateY(0px) !important;
        }
    }
    .overlay {
        background: $blog-overlay-dark;
    }
    &:hover {
        transform: translateY(-10px);
        .overlay {
            opacity: 0.6;
        }
        .author,
        .teacher,
        .preview,
        .course-fee {
            opacity: 1;
        }
    }
    .blog-list-meta {
        position: absolute;
        bottom: 24px;
    }

    .preview {
        color: $white !important;
    }
}

//Sidebar
.sidebar {
    .widget {        
        .blog-categories {
            li {
                padding-bottom: 10px;
                &:last-child {
                    padding-bottom: 0;
                }
                a, span {
                    font-size: 15px;
                }
                a {
                    color: $dark;
                    transition: all 0.5s ease;
                    &:hover {
                        color: $primary;
                    }
                }
            }
        }      
        .post-recent {
            padding-bottom: 15px;
            &:last-child {
                padding-bottom: 0;
            }
            .post-recent-thumb {
                width: 25%;
            }
            
            .post-recent-content {
                width: 75%;
                padding-left: 10px;
                a {
                    display: block;
                    color: $dark;
                    font-size: 15px;
                    transition: all 0.5s ease;
                    &:hover {
                        color: $primary;
                    }
                }
                span {
                    font-size: 13px;
                }
            }
        }
    }
}
.tagcloud {
    > a {
        background: $gray-100;
        color: $dark;
        display: inline-block;
        font-size: 9px;
        letter-spacing: 1px;
        margin: 5px 10px 5px 0;
        padding: 8px 12px 7px;
        text-transform: uppercase;
        transition: all 0.5s ease;
        font-weight: 600;
        &:hover {
            background: $primary;
            color: $white;
        }
    }
}

// single blog
.media-list {
    .media {
        .media-heading {
            &:hover{
                color: $primary !important;
            }
        }
    }
    .sub-comment {
        border-left: 2px dashed $border-color;
    }
}

.blog .overlay,
.job-box .job-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0; 
    transition: all 0.5s ease;
}

//Jobs and Careers

@each $name,
$value in $theme-colors {
    .job-box {
        &.job-#{$name} {
            .position {
                a {
                    &:hover {
                        color: $value !important;
                    }
                }
            }
            .company-name {
                &:hover {
                    color: $value !important;
                }
            }
            .title,
            .readmore {
                &:hover {
                    color: $value !important;
                }
            }

            .icons {
                color: $value !important;
            }

            &.candidate-list, 
            &.company-list {
                .name {
                    &:hover {
                        color: $value !important;
                    }
                }
            }

            .bg {
                background-color: #{$value} !important;
            }

            .bg-soft {
                background-color: rgba($value, 0.1) !important;
                border: 1px solid rgba($value, 0.1) !important;
                color: #{$value} !important;
            }

            .btn-soft {
                background-color: rgba($value, 0.05) !important;
                border: 1px solid rgba($value, 0.05) !important;
                color: #{$value} !important;
                box-shadow: 0 3px 5px 0 rgba($value, 0.1);
                &:hover, &:focus, &:active, &.active, &.focus{
                    background-color: #{$value} !important;
                    border-color: #{$value} !important;
                    color: $white !important;
                }
            }
        }
    }
}

.job-box {
    transition: all 0.5s ease;
    .job-overlay {
        opacity: 0.6;
    }
    .head {
        position: absolute;
        top: 15px;
        right: 15px;
    }
    .position {
        position: absolute;
        top: 15px;
        left: 15px;
        a {
            transition: all 0.5s ease;
        }
    }
    .firm-logo {
        position: absolute;
        left: 0;
        right: 0;
        height: 60px;
        width: 60px;
        line-height: 60px;
        margin: 0 auto;
        top: -30px;
    }
    .company-name {
        transition: all 0.5s ease;
    }

    &.candidate-list {
        .like {
            -webkit-text-stroke: 1px $muted;
            -webkit-text-fill-color: transparent;
            &:hover, &:focus {
                -webkit-text-stroke: $danger;
                -webkit-text-fill-color: $danger;
            }
        }
    }
    
    &.candidate-list, 
    &.company-list {
        transition: all 0.5s ease;
        &:hover {
            box-shadow: $shadow-md !important;
        }
    }

    &:hover {
        transform: translateY(-5px);
    }
}


@media (min-width: 1024px) {
    .job-profile {
        top: -378px;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .job-profile {
        top: -338px;
    }
}

@media (max-width: 767px) {
    .company-logo {
        float: none !important;
        text-align: left !important;
    }
}


//Categories
@each $name,
$value in $theme-colors {
    .categories {
        &.category-#{$name} {
            .title {
                &:hover {
                    color: $value !important;
                }
            }
        }
    }
}
.categories {
    transition: all 0.5s ease;
    &:hover {
        transform: translateY(-10px);
        box-shadow: $shadow;
    }
}